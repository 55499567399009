	/*
  	Flaticon icon font: Flaticon
  	Creation date: 04/02/2020 07:01
  	*/
	
	@font-face {
	    font-family: "Flaticon";
	    src: url("./Flaticon.eot");
	    src: url("./Flaticon.eot?#iefix") format("embedded-opentype"), url("./Flaticon.woff2") format("woff2"), url("./Flaticon.woff") format("woff"), url("./Flaticon.ttf") format("truetype"), url("./Flaticon.svg#Flaticon") format("svg");
	    font-weight: normal;
	    font-style: normal;
	}
	
	@media screen and (-webkit-min-device-pixel-ratio:0) {
	    @font-face {
	        font-family: "Flaticon";
	        src: url("./Flaticon.svg#Flaticon") format("svg");
	    }
	}
	
	[class^="flaticon-"]:before,
	[class*=" flaticon-"]:before,
	[class^="flaticon-"]:after,
	[class*=" flaticon-"]:after {
	    font-family: Flaticon;
	    font-size: 20px;
	    font-style: normal;
	    margin-left: 20px;
	}
	
	.flaticon-play-button:before {
	    content: "\f100";
	}
	
	.flaticon-wounded:before {
	    content: "\f101";
	}
	
	.flaticon-value:before {
	    content: "\f102";
	}
	
	.flaticon-hand:before {
	    content: "\f103";
	}
	
	.flaticon-hook:before {
	    content: "\f104";
	}
	
	.flaticon-LOANLY:before {
	    content: "\f105";
	}
	
	.flaticon-kidnapping:before {
	    content: "\f106";
	}
	
	.flaticon-star:before {
	    content: "\f107";
	}
	
	.flaticon-up-arrow:before {
	    content: "\f108";
	}
	
	.flaticon-balance-scale:before {
	    content: "\f109";
	}
	
	.flaticon-family:before {
	    content: "\f10a";
	}
	
	.flaticon-book:before {
	    content: "\f10b";
	}
	
	.flaticon-auction:before {
	    content: "\f10c";
	}
	
	.flaticon-website:before {
	    content: "\f10d";
	}
	
	.flaticon-customer-service:before {
	    content: "\f10e";
	}
	
	.flaticon-live-show:before {
	    content: "\f10f";
	}
	
	.flaticon-folder:before {
	    content: "\f07b";
	}
	
	.flaticon-tax:before {
	    content: "\f10e";
	}
	
	.flaticon-bill:before {
	    content: "\f10f";
	}
	
	.flaticon-report:before {
	    content: "\f10a";
	}