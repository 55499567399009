.menu-item-has-children>a:after {
    display: inline-block;
    margin-left: .55em;
    content: "\f107";
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    transition: transform 0.4s ease;
}

.menu-item-has-children:hover>a:after {
    transform: rotate(180deg);
}

.nav-item {
    position: relative;
}


/* Calculator classes */

.content_calc {
    /* display: flex; */
    justify-content: center;
    width: 80%;
    margin: auto;
}

.ETableCellText {
    font-size: 18px !important;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.ETableCellVal {
    text-align: right !important;
    font-size: 18px !important;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 500;
}

.calApp {
    width: 100%;
    /* border: 2px #CCC solid; */
    padding: 20px 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    /* background-color: lemonchiffon; */
}

.chart_content {
    margin-top: 10px;
}

.chart_div {
    width: 100%;
    justify-content: center;
    display: flex;
    margin-bottom: 10px;
}


/* .chart {
    width: 400px;
    height: 400px;
    background-color: #8b8b8b;
} */

.input_slider {
    font-size: 14px;
    font-weight: 400;
    width: 100%;
    box-shadow: inset 0 0 10px #8b8b8b;
    padding: 5px 7px;
    text-align: center;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.calHeading {
    text-align: "center";
    color: fireBrick;
}


/* Accordion */

.accordion .card {
    display: flex;
    flex-wrap: wrap;
}

.accordion .card .collapseparent {
    order: 2;
}

.accordion .card .card-header {
    width: 100%;
    flex-basis: 100%;
    order: 1;
    padding: 0;
    border-radius: 0;
}

.faqs-accordion .card .card-header .collapsebtn:after {
    content: 'View';
    color: #0070ba;
}

.accordion .card .collapseparent.show+.card-header .collapsebtn:after {
    content: '-';
    font-size: 26px;
    font-weight: 600;
    color: #fff;
    transition: all 0.5 ease-in-out;
}

.accordion>.card:not(:last-of-type) {
    border-bottom: 1px solid #0070ba;
}

.sidebar_wrap .sidebar .sidebar_widgets .popular_post li .post .post-wrapper .popular_post_img {
    height: 90px;
}

.user-info-tabs .step-app .step-steps>li.go2998626915>a>.number:before,
.user-info-tabs .step-app .step-steps>li.go2998626915>a>.number:before {
    content: '\f00c';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    color: #fff;
}

.user-info-tabs .step-app .step-steps>li.go2998626915>a>.number,
.user-info-tabs .step-app .step-steps>li.go2998626915>a>.number {
    background-color: #0070ba;
}

.user-info-tabs .step-app .step-steps>li.go2998626915>a>.step-name,
.user-info-tabs .step-app .step-steps>li.go2998626915>a>.step-name {
    color: #0070ba;
}

.user-info-tabs .step-app .step-steps>li.go2998626915:before {
    background-color: #0070ba;
}

.extra-page {
    height: 100vh;
}

.overlapsec+.sub-header .section-padding {
    padding-bottom: 160px;
}

.user-dashboard-sec .dashboard-first-sec .left-side h5:after {
    display: none;
}

.user-dashboard-sec .dashboard-first-sec .left-side h5 a:after {
    display: inline-block;
    margin-left: .55em;
    content: "\f107";
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    color: #0070ba;
    font-size: 22px;
    line-height: 1.2;
    position: relative;
    top: 2px;
    transform: rotate(180deg);
    transition: 0.3s;
}

.user-dashboard-sec .dashboard-second-sec[aria-expanded="false"]:after,
.user-dashboard-sec .dashboard-first-sec .left-side h5 a[aria-expanded="false"]:after {
    transform: none;
}

.user-dashboard-sec .dashboard-second-sec[aria-expanded="false"] .right-side {
    opacity: 1;
}

.user-dashboard-sec .dashboard-second-sec-large .left-side .pie-chart-area .active-border,
.user-dashboard-sec .dashboard-second-sec .right-side .pie-chart-box .active-border {
    background: none;
}

.user-dashboard-sec .dashboard-second-sec-large .left-side .pie-chart-area .circle,
.user-dashboard-sec .dashboard-second-sec .right-side .pie-chart-box .circle {
    top: 0;
    left: 0;
    width: auto;
    height: auto;
}

.theme_btn {
    color: rgb(106, 124, 228);
    background: #d6d7da;
    text-align: center !important;
    font: 600 16px/50px "Nunito", sans-serif;
    display: inline-block;
    padding: 0 20px;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    z-index: 1;
    margin-top: 25px;
}

a :hover {
    color: #fff
}

.service-details {
    display: block;
    overflow: hidden;
    padding-top: 150px;
    padding-bottom: 90px;
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.services_details h2,
.loan_type h2,
.features h2,
.accordion_area .container h2 {
    font-size: 40px;
    font-weight: 400;
    color: #2759ab;
    padding-bottom: 25px;
    text-align: left;
}

.about_title {
    font-size: 18px !important;
}

.about_title,
.loan_desc {
    font-family: 'Montserrat', sans-serif;
}

.about-gallery {
    width: 800px;
    height: 400px;
}

.features {
    display: block;
    overflow: hidden;
    text-align: center;
    padding-top: 90px;
    padding-bottom: 10px;
}

.features .loan {
    text-align: center;
    margin-bottom: 30px;
    padding: 40px;
}

.features .loan i {
    height: 82px;
    line-height: 82px;
    border-radius: 50%;
    width: 82px;
    background: #eaeaea;
    text-align: center;
    display: block;
    color: #2759ab;
    margin: 0 auto;
    transition: all 300ms linear 0s;
}

.features .loan i :hover {
    background: #39548a;
    color: #fff;
}

.features .loan h4 {
    font: 400 18px/28px "Nunito", sans-serif;
    color: #38393a;
    padding-top: 25px;
}

.features .loan i:before {
    font-size: 36px;
}

.service-details img {
    vertical-align: middle;
    border-style: none;
}

.service_banner {
    background: #2759ab;
}

.eligibility_criteria_area {
    display: block;
    overflow: hidden;
}

.eligibility_criteria_area h3 {
    color: #fff;
    font: 600 28px/35px "Nunito", sans-serif;
    padding-bottom: 45px;
    padding-left: 25px;
}

.pos-features h3 {
    color: #fff;
    font: 600 28px/35px "Nunito", sans-serif;
    padding-bottom: 25px;
    padding-left: 25px;
}

.eligibility_criteria_area .eligibility_criteria {
    width: calc(50% - 35px);
    float: left;
    background: #2759ab;
    padding-top: 70px;
    padding-bottom: 120px;
    max-height: 550px;
}

.eligibility_criteria_area ul li {
    padding-left: 40px;
    position: relative;
    font: 400 18px/35px "Nunito", sans-serif;
    color: #f3f6fa;
    padding-bottom: 18px;
}

.bg-features {
    background: #2759ab;
}

.pos-features ul li {
    padding-left: 40px;
    position: relative;
    font: 400 18px/35px "Nunito", sans-serif;
    color: #f3f6fa;
    padding-bottom: 10px;
}

.eligibility_criteria_area .required_approval_area {
    width: calc(50% + 35px);
    padding-top: 70px;
    float: right;
    padding-left: 40px;
    background-color: #eceef5;
    padding-bottom: 120px;
    max-height: 550px;
}

.eligibility_criteria_area .required_approval_area .required_approval h3 {
    color: #2759ab;
    /* padding-top: 25px; */
    padding-left: 15px;
}

.eligibility_criteria_area .required_approval_area .required_approval ul li {
    color: #000;
}

.eligibility_criteria_area ul,
.pos-features ul {
    list-style-type: none;
}


/* ------------------------
service Details About
------------------------ */


/* .about-heading {
    font-weight: 700 !important;
    text-shadow: white 2px;
} */

.accordion_area {
    display: block;
    overflow: hidden;
    padding: 100px 0;
    /* background: rgb(241, 241, 241); */
}

.accordion_area .faq_accordion .item .heding {
    font: 600 20px/35px "Nunito", sans-serif;
    color: #fff;
    display: block;
    padding: 15px 45px;
    border: 1px solid #e6e6e6;
    border-radius: 34px;
    margin-bottom: 30px;
    position: relative;
    background: #2759ab;
}

.accordion_area .faq_accordion .item p {
    padding-bottom: 20px;
    padding-top: 5px;
    font: 400 18px/30px "Nunito", sans-serif;
    max-width: 1140px;
}

.accordion_area .faq_accordion .item .accordion_content {
    padding-bottom: 20px;
}

.accordion_area .faq_accordion .item .heding:after {
    content: "^";
    font: normal normal normal 20px/1 FontAwesome;
    color: #fff;
    position: absolute;
    right: 35px;
    top: 50%;
    transform: translateY(-50%);
}

.accordion_area .faq_accordion .item .heding.collapsed {
    background: #fff;
    color: #4d4e4f;
}

.accordion_area h2 {
    text-align: center;
    font: 600 28px/1 "Nunito", sans-serif;
    color: #38393a;
    padding-bottom: 60px;
}

.header-alert {
    text-align: center;
    background: #d6d7da;
    opacity: 0.6;
    font-weight: 600;
    color: #000 !important;
    /* height: 50px !important; */
}

.a-disable, .a-disable:hover, .a-disable:link ,.a-disable:visited{
    text-decoration: none;
    color: #fff;
}

.poweredby{
    font-size: 16px;
    font-weight: bold;
}

@media (max-width: 786px) {
    .eligibility_criteria_area .eligibility_criteria {
        width: 100%;
        padding-bottom: 0;
    }

    .eligibility_criteria_area .required_approval_area {
        width: 100%;
        padding-bottom: 0;
    }
}

/* @media (max-width: 1199px) {
    .eligibility_criteria_area .eligibility_criteria {
        padding-left: 15px;
    }
} */

@media (min-width:576px) {

    /* .container {
        max-width: 540px;
    } */
    .features .loan {
        padding: 20px;
    }

}

@media (max-width:575px) {
    .features .loan {
        padding: 10px;
    }

    .about-gallery {
        width: 400px;
        height: 400px;
        align-items: center;
    }
}

@media (max-width: 460px) {
    .overlapsec+.sub-header .section-padding {
        padding-bottom: 80px;
    }
}

@media (max-width: 991px) {
    .services_details h2 {
        /* font-size: 25px; */
        line-height: 35px;
    }

    .service-details {
        padding-top: 100px;
    }

    .menu-item-has-children>a {
        display: flex !important;
        align-items: center;
        justify-content: space-between;
    }

    .eligibility_criteria_area .required_approval_area {
        padding-left: 15px;
        /* width: 100%; */
        float: none;
        padding-top: 50px;
        display: block;
        overflow: hidden;
    }
}